@font-face {
  font-family: Objective;
  src: url(./assets/fonts/Objective-Regular.woff);
}

@font-face {
  font-family: Objective;
  font-weight: 500;
  src: url(./assets/fonts/Objective-Medium.woff);
}

@font-face {
  font-family: Objective;
  font-weight: bold;
  src: url(./assets/fonts/Objective-Bold.woff);
}

:root {
  font-family: Objective, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: black;

  /*color-scheme: light dark;*/
  /*color: rgba(255, 255, 255, 0.87);*/
  /*background-color: #242424;*/
  background-color: white;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  text-decoration: none;
}
